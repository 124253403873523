




import LabSim from '@/tasks/components/simulations/LabSim.vue';
import {defineComponent, PropType} from '@vue/composition-api';

export interface INewDensityOfLiquidsAndSolidsInput {
  densityOfWater: number;
  densityOfUnknownLiquid: number;
  densityOfMetal: number;
}

export default defineComponent({
  name: 'LabNewDensityOfLiquidsAndSolids',
  components: {LabSim},
  inheritAttrs: false,
  props: {
    densityOfWater: {
      type: Number as PropType<number>,
      default: 0.998,
    },
    densityOfUnknownLiquid: {
      type: Number as PropType<number>,
      default: 0.998,
    },
    densityOfMetal: {
      type: Number as PropType<number>,
      default: 2.7,
    },
  },
  computed: {
    labData(): INewDensityOfLiquidsAndSolidsInput {
      return {
        densityOfWater: this.densityOfWater,
        densityOfUnknownLiquid: this.densityOfUnknownLiquid,
        densityOfMetal: this.densityOfMetal,
      };
    },
    stringifiedLabData(): string {
      return JSON.stringify(this.labData);
    },
  },
});
